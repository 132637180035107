import React from 'react';
import { FaPhone, FaEnvelope, FaFacebook, FaInstagram } from 'react-icons/fa';

function Contact() {
    return (
        <section id="contact" className="contact">
            <h2>צור קשר</h2>
            <p><FaPhone /> בשיר עביד - 0506051133</p>
            <p><FaEnvelope /> basheer.lock.24@gmail.com</p>
            <div className="social-links">
                <a href="https://www.facebook.com/profile.php?id=61556627707825"><FaFacebook /></a>
                <a href="https://www.instagram.com/basheer_man3olan"><FaInstagram /></a>
            </div>
        </section>
    );
}

export default Contact;
