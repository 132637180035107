import React from 'react';
import { Fade } from 'react-awesome-reveal';

function Services() {
    return (
        <Fade duration={500}>
            <section id="services" className="services">
                <h2>השירותים שלנו</h2>
                <ul>
                    <li>פריצת כל סוגי הדלתות</li>
                    <li>פריצת מנעולים</li>
                    <li>פריצת כספות</li>
                    <li>פריצת רכבים</li>
                    <li>החלפת צילינדרים ומנגנונים לכל סוגי הדלתות</li>
                    <li>שכפולים לכל סוגי המפתחות</li>
                    <li>התקנת מנעול ביומטרי (מנעול חכם)</li>
                    <li>שירות 24/7 בכל הצפון</li>
                </ul>
            </section>
        </Fade>
    );
}

export default Services;
