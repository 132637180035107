import React from 'react';
import img1 from './img/1.jpg';
import img2 from './img/2.jpg';
import img3 from './img/3.jpg';
import img4 from './img/4.jpg';
import img5 from './img/5.jpg';

function SmartLocks() {
    return (
        <section className="smart-locks">
            <h2>כל דלת יכולה להיות חכמה</h2>
            <div className="lock-card">
                <img src={img1} alt="מנעול חכם eLock" className="lock-image"/>
                <h3>מנעול חכם eLock</h3>
                <p>מנעול חכם elock המתאים לכל סוגי הדלתות, עם שליטה מרחוק דרך אפליקציה, אפשרות לניהול הגישה באמצעות מפתחות וירטואליים, והרשאות כניסה מותאמות למשתמשים שונים. כולל תיעוד כניסות וחיי סוללה ארוכים, מתאים לשומרי שבת, ועשוי פלדת אל-חלד, במידות 75x260x65 מ"מ.</p>
                <p>**אפשר גם אותו המוצר בלי טביעת אצבע**</p>
            </div>
            <hr />
            <div className="lock-card">
                <img src={img2} alt="מנעול חכם eLock לדלתות אלומיניום" className="lock-image"/>
                <h3>מנעול חכם eLock לדלתות אלומיניום</h3>
                <p>מנעול חכם elock המתאים לכל סוגי הדלתות, עם ממשק ניהול הרשאות כניסה ידידותי ונעילת בריחים מלאה באמצעות טביעת אצבע או קוד אישי. מתאים לשומרי שבת, וכולל חיי סוללה ארוכים, עשוי פלדת אל-חלד, במידות 73x192x74 מ"מ.</p>
            </div>
            <hr />
            <div className="lock-card">
                <img src={img3} alt="מנעול חכם eLock לדלתות זכוכית" className="lock-image"/>
                <h3>מנעול חכם eLock לדלתות זכוכית</h3>
                <p>מנעול חכם elock המתאים לדלתות אלומיניום ועץ, עם שליטה מרחוק דרך אפליקציה מתקדמת וניהול הגישה באמצעות מפתחות וירטואליים הניתנים להעברה. כולל הגדרת סוגי משתמשים שונים והרשאות כניסה לפי לו"ז, תיעוד כניסות וחיי סוללה ארוכים, עשוי פלדת אל-חלד, במידות 38x280x70 מ"מ.</p>
            </div>
            <hr />
            <div className="lock-card">
                <img src={img4} alt="מנעול חכם eLock פנים" className="lock-image"/>
                <h3>מנעול חכם eLock לדלתות פנים</h3>
                <p>מנעול חכם elock המתאים לדלתות זכוכית במשרדים, אולמות תצוגה וחנויות, עם שליטה מרחוק דרך אפליקציה מתקדמת וניהול הגישה באמצעות מפתחות וירטואליים הניתנים להעברה. ניתן להגדיר סוגי משתמשים שונים והרשאות כניסה לפי לו"ז, תיעוד כניסות וחיי סוללה ארוכים, במידות יחידת דלת: 65x160 מ"מ ויחידת משקוף: 40x160 מ"מ.</p>
            </div>
            <hr />
            <div className="lock-card">
                <img src={img5} alt="מנעול חכם eLock ביומטרי" className="lock-image"/>
                <h3>מנעול חכם eLock ביומטרי</h3>
                <p>מנעול חכם elock המתאים לכל סוגי דלתות הפנים, עם שליטה מרחוק דרך אפליקציה מתקדמת וניהול הגישה באמצעות מפתחות וירטואליים הניתנים להעברה. ניתן להגדיר סוגי משתמשים שונים והרשאות כניסה לפי לו"ז, מעקב אחר כניסות משתמשים ותיעוד כניסות באמצעות האפליקציה. מתן הרשאות כניסה לפי לו"ז, וחיי סוללה ארוכים במידות רוחב 150 מ"מ ועומק 70 מ"מ.</p>
            </div>
        </section>
    );
}

export default SmartLocks;
