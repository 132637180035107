import React from 'react';
import logo from './img/logo.png';

function Header() {
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className="header">
            <div className="header-titles">
                <img src={logo} alt="Logo" className="logo" />
                <h1 className="header-title">בשיר המנעולן</h1>
                <h1 className="header-title">מנעולן שמבין מנעולים</h1>
                <div className="title-divider"></div>
            </div>
            <nav>
                <a href="#services" onClick={() => scrollToSection('services')}>שירותים</a>
                <a href="#about" onClick={() => scrollToSection('about')}>אודות</a>
                <a href="#contact" onClick={() => scrollToSection('contact')}>צור קשר</a>
            </nav>
        </header>
    );
}

export default Header;
