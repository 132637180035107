import './App.css';
import React from 'react';
import Header from './Header';
import Welcome from './Welcome';
import Services from './Services';
import SmartLocks from './SmartLocks';
import Contact from './Contact';


function App() {
    return (
        <div>
            <Header />
            <Welcome />
            <Services />
            <SmartLocks />
            <Contact />
        </div>
    );
}

export default App;
