import React from 'react';

function Welcome() {
    return (
        <section id="about" className="welcome">
            <h2>ברוכים הבאים!</h2>
            <p>ברוכים הבאים לעולם המפתחות החדש של המנעולנות היצירתית!</p>
            <p>אני בשיר, מנעולן שמבין מנעולים, מורשה ומוסמך בפיקוח המדינה, ואני כאן כדי לשדרג את המפתחות שלכם לחוויה חדשה לגמרי.</p>
            <p>בעזרתי, תוכלו לפתוח דלתות ללא מאמץ, להחליף מנעולים בקלות ולקבל שירות מקצועי ואדיב בכל עת.</p>
            <p>כל זה עם המון יצירתיות וחשיבה מחוץ לקופסה. צרו קשר עוד היום ותתחילו לחוות את השירות שידהים אותכם.</p>
        </section>
    );
}

export default Welcome;
